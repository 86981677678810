<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>在庫詳細</CCardHeader>
        <CCardBody>
          <div v-if="loading">
            <LoadingCompoment />
          </div>
          <div v-else>
            <CRow v-for="field in fields" :key="field['key']">
              <CCol class="text-left"> {{ field["label"] }} </CCol>
              <CCol class="text-left">
                <template v-if="isStatusInStockOrDeadStock(field['key'])">
                  <CSelect
                    id="status"
                    class="mb-0 width-100"
                    size="sm"
                    @change="selectStatus"
                    v-model="status"
                    :options="statusOptions"
                  />
                </template>
                <template v-else-if="field['key'] === 'pp_bs_code'">
                  <CSelect
                    id="ppbs"
                    class="mb-0 width-200"
                    size="sm"
                    @change="selectPpbs"
                    v-model="pp_bs_code"
                    :options="ppbsOptions"
                    
                  />
                </template>
                <template v-else-if="field['key'] === 'is_special'">
                  <CSelect
                    id="special"
                    class="mb-0 width-100"
                    size="sm"
                    @change="selectSpecial"
                    v-model="is_special"
                    :options="specialOptions"
                  />
                </template>
                <template v-else-if="field['key'] === 'price'">
                  <CInput
                    id="price"
                    class="mb-0 width-100"
                    size="sm"
                    label=""
                    type="number"
                    min="0"
                    v-model="price"
                  />
                </template>
                <template v-else>
                  {{ stock[field["key"]] || "" }}
                </template>
              </CCol>
            </CRow>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol class="text-center">
      <CButton color="success mr-5" @click="updateStatus"> 更新 </CButton>
      <CButton color="primary" @click="() => $router.back()"> 戻る </CButton>
    </CCol>
  </CRow>
</template>

<script>
import { numberWithCommas } from "@/utils/utils"
import ResourceApi from "@/api/resourceApi"
import Vue from "vue"

const stockApi = new ResourceApi("stocks")

export default {
  data() {
    return {
      loading: false,
      stock: {},
      fields: [
        { key: "item_id", label: "商品ID" },
        { key: "instock_date", label: "入荷日" },
        { key: "status", label: "ステータス" },
        { key: "shelf_name", label: "棚" },
        { key: "item_code", label: "品番" },
        { key: "item_name", label: "品名" },
        { key: "size", label: "製品サイズ" },
        { key: "color", label: "カラー名" },
        { key: "cost", label: "仕入原価" },
        { key: "price", label: "販売価格" },
        { key: "retail_price", label: "プロパー上代" },
        { key: "business_year", label: "年度" },
        { key: "month", label: "展開月" },
        { key: "season_name", label: "シーズン" },
        { key: "pp_bs_code", label: "PP/BS" },
        { key: "is_special", label: "優待" },
        { key: "major_category", label: "大分類" },
        { key: "middle_category", label: "中分類" },
        { key: "minor_category", label: "小分類" },
      ],
      stockId: this.$route.params.id,
      statusOptions: [
        { label: "在庫", value: 1 },
        { label: "不良在庫", value: 4 },
      ],
      ppbsOptions: [
        { label: "PP", value: "PP" },
        { label: "BS", value: "BS" },
      ], 
      specialOptions: [
        { label: "非優待", value: 0 },
        { label: "優待", value: 1 },
      ],
      selectedStatus: null,
      selectedPpbs: null,
      selectedSpecial: null,
    }
  },

  computed: {
    status: {
      get() {
        return this.statusOptions.find((i) => i.label == this.stock.status)?.value
      },
      set(newValue) {
        this.selectedStatus = newValue
      },
    },
    pp_bs_code: {
      get() {
        if (this.stock.pp_bs_code) {
          return this.ppbsOptions.find((i) => i.label == this.stock.pp_bs_code)?.value
        }  
        return ""
      },
      set(newValue) {
        this.selectedPpbs = newValue
      },
    },
    is_special: {
      get() {
        return this.specialOptions.find((i) => i.label == this.stock.is_special)?.value
      },
      set(newValue) {
        this.selectedSpecial = newValue
      },
    },
    price: {
      get() {
        return this.stock.price
      },
      set(newValue) {
        this.stock.price = newValue
      },
    },
  },
  created() {
    this.getStock()
  },

  methods: {
    isStatusInStockOrDeadStock(key) {
      const statuses = ["在庫", "不良在庫"]
      return key === "status" && statuses.includes(this.stock[key])
    },

    getStock() {
      this.loading = true

      if (!this.stockId) {
        this.loading = false
        return
      }

      stockApi
        .show({ id: this.stockId })
        .then((response) => {
          this.handleStocksData(response.data)
          this.handleOption(response.data)
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    handleStocksData(data) {
      this.stock = {
        id: data.id,
        instock_date: data.instock_date,
        item_id: data.item_id,
        status: data.status_new,
        val_status: data.status,
        shelf_name: data.shelf.name,
        item_code: data.item.item_master.item_code,
        item_name: data.item.item_master.name,
        size: data.item.item_master.size.name,
        color: data.item.item_master.color.color_code + ':' + data.item.item_master.color.name,
        cost: numberWithCommas(data.item.item_master.cost),
        price: data.price ? data.price : data.item.item_master.price,
        retail_price: numberWithCommas(data.item.item_master.retail_price),
        business_year: data.item.item_master.business_year,
        month: data.item.item_master.month,
        season_name: data.item.item_master.season.name,
        pp_bs_code: data.pp_bs_code,
        item_master_ppbs: data.item.item_master.pp_bs_code,
        is_special: data.special,
        val_special: data.is_special,
        major_category: data.item.item_master.major_category.name,
        middle_category: data.item.item_master.middle_category.name,
        minor_category: data.item.item_master.minor_category.name,
      }
    },

    handleOption(data) {
      if (!data.pp_bs_code) {
        this.ppbsOptions.unshift({ label: "商品マスタの値を優先"+"("+data.item.item_master.pp_bs_code+")", value: "" });
      }    
    },

    selectStatus(e) {
      const val = e.target.value
      this.selectedStatus = val ? parseInt(val) : ''
    },

    selectPpbs(e) {
      const val = e.target.value
      this.selectedPpbs = val 
    },

    selectSpecial(e) {
      const val = e.target.value
      this.selectedSpecial = val 
    },

    updateStatus() {
      const params = {
        id: this.stockId,
        status: this.selectedStatus ? this.selectedStatus : this.stock.val_status,
        pp_bs_code: this.selectedPpbs ? this.selectedPpbs : this.stock.pp_bs_code,
        is_special: this.selectedSpecial ? this.selectedSpecial : this.stock.val_special,
        price: this.stock.price ? this.stock.price : null,
      }

      stockApi
        .edit(params)
        .then(() => {
          Vue.$toast.success("Status successfully updated!")
        })
        .catch(() => {
          Vue.$toast.success("Error in updating status")
        })
    },
  },
}
</script>

<style scoped>
.width-100 {
  width: 100px !important;
}
.width-200 {
  width: 200px !important;
}  
</style>
